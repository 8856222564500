import { captureMessage } from '@sentry/nextjs';
import { createContentfulClient, createServerClient } from 'apollo/clients';
import { GetPromotionBannersDocument, GetPromotionBannersQuery } from 'generated/contentful-graphql';
import { GetServerSideProps, GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { contentfulLocale } from 'utils/contentfulLocale';
import { SWR_CACHE_CONTROL } from 'utils/swrCacheControl';
import Home from 'views/Home';
import { BannerItem, BannerSectionVariant } from 'views/Home/HomeBannerSection';
import { GetLobbyGamesDocument } from '../generated/graphql';
export const HomePage = ({
  banners,
  gamesData
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return <>
      <Head>
        <title>Shuffle.com | Crypto Casino & VIP Betting Platform</title>
      </Head>
      <Home banners={banners} gamesData={gamesData} />
    </>;
};
export const getLobbyGames = async () => {
  try {
    const client = createServerClient();
    const lobbyGames = await client.query({
      query: GetLobbyGamesDocument
    });
    return lobbyGames.data;
  } catch {
    return [];
  }
};

/**
 * Fetches the home page props to be used from other
 * pages to pass into the <Home/> component.
 */
export const getHomePageComponentProps = async (preview: boolean, locale?: string): Promise<GetHomePageComponentPropsReturn> => {
  // Create a new client on each request to avoid cache issues.
  const contentfulClient = createContentfulClient({
    preview
  });
  try {
    // Fetch page data from Contentful
    const response = await contentfulClient.query<GetPromotionBannersQuery>({
      query: GetPromotionBannersDocument,
      variables: {
        preview,
        now: new Date().toISOString(),
        locale: contentfulLocale(locale)
      },
      fetchPolicy: 'cache-first'
    });

    // Add promotion to page props; if it doesn't exist; show the user client-side.
    const promotionItems = response.data.promotionCollection?.items ?? [];
    const banners: BannerItem[] = promotionItems.map(item => ({
      type: ((item?.type ?? 'casino') as BannerSectionVariant),
      href: `/promotions/${item?.slug}`,
      image: item?.image?.url ?? '',
      alt: item?.image?.description ?? ''
    }));
    return {
      banners
    };
  } catch (e) {
    // Report to Sentry
    captureMessage(`Promotion banners request failed ${(e as Error)?.message || ''}`, {
      extra: {
        file: 'src/pages/index.tsx'
      },
      level: 'error'
    });
    return {
      banners: null
    };
  }
};
export const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
  context.res.setHeader('Cache-Control', SWR_CACHE_CONTROL.STANDARD);
  const isPreview = context.query.cms === 'preview';
  const [localeProps, homePageProps, lobbyGames] = await Promise.all([context.locale ? serverSideTranslations(context.locale, ['common', 'errors']) : undefined, getHomePageComponentProps(isPreview, context.locale), getLobbyGames()]);
  return {
    props: {
      casinoLayout: true,
      activityBoard: true,
      gamesData: lobbyGames,
      ...homePageProps,
      ...localeProps
    }
  };
};
type GetHomePageComponentPropsReturn = {
  banners: BannerItem[] | null;
};
export default HomePage;